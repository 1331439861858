import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@emotion/react';

const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';

const ImgContainer = ({ src }) => {
    return (
        <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xs: 'none', md: 'flex' }, width: '100%' }}>
            <Box
                variant='outlined'
                sx={{
                    m: 'auto',
                    alignSelf: 'center',
                    height: { xs: 200, sm: 500 },
                    width: '100%',
                    border: 1,
                    borderRadius: 5,
                    borderColor: (theme) => {
                        if (theme.palette.mode === 'light') {
                            return 'grey.200';
                        }
                        return 'grey.800';
                    },
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(${src})`,
                }}
            />
        </Grid>
    );
};

export default function LocationCards({
    direction = 'left',
    id = '',
    img,
    items = [],
}) {
    const { palette } = useTheme();
    return (
        <Box id={id} sx={{ py: { xs: 8, sm: 10 } }}>
            <Grid container spacing={6}>
                {direction === 'right' ? <ImgContainer src={img} /> : null}
                <Grid item xs={12} md={6}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                            width: '100%',
                        }}>
                        <Box
                            variant='outlined'
                            sx={{
                                m: 'auto',
                                alignSelf: 'center',
                                height: { xs: 200, sm: 500 },
                                width: '100%',
                                border: 1,
                                borderRadius: 5,
                                borderColor: (theme) => {
                                    if (theme.palette.mode === 'light') {
                                        return 'grey.200';
                                    }
                                    return 'grey.800';
                                },
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundImage: `url(${img})`,
                            }}
                        />
                    </Grid>
                    <Stack
                        direction='column'
                        justifyContent='center'
                        alignItems='flex-start'
                        spacing={2}
                        useFlexGap
                        sx={{
                            width: '100%',
                            display: { xs: 'flex', sm: 'flex' },
                        }}>
                        {items.map((item) => {
                            const { title, address, phone } = item;
                            return (
                                <Card
                                    variant='outlined'
                                    sx={{
                                        p: 3,
                                        height: 'fit-content',
                                        width: '100%',
                                        background: 'none',
                                        borderColor: (theme) => {
                                            if (
                                                theme.palette.mode === 'light'
                                            ) {
                                                return 'grey.200';
                                            }
                                            return 'grey.800';
                                        },
                                    }}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            textAlign: 'left',
                                            flexDirection: {
                                                xs: 'column',
                                                md: 'row',
                                            },
                                            alignItems: { md: 'center' },
                                            gap: 2.5,
                                        }}>
                                        <Box sx={{ textTransform: 'none' }}>
                                            <Typography
                                                color={
                                                    palette.mode === 'light'
                                                        ? primary_color
                                                        : primary_color_dark
                                                }
                                                variant='body2'
                                                fontWeight='bold'
                                                fontSize={26}>
                                                {title}
                                            </Typography>
                                            <Typography
                                                color='text.secondary'
                                                variant='body2'
                                                fontSize={20}
                                                sx={{ my: 0.5 }}>
                                                Address: {address}
                                            </Typography>
                                            <Typography
                                                color='text.secondary'
                                                variant='body2'
                                                fontSize={20}
                                                sx={{ my: 0.5 }}>
                                                Phone: {phone}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            );
                        })}
                    </Stack>
                </Grid>
                {direction === 'left' ? <ImgContainer src={img} /> : null}
            </Grid>
        </Box>
    );
}
