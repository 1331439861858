import React, { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Button, Box, CircularProgress, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { green } from '@mui/material/colors';
import parse, { domToReact } from 'html-react-parser';
import Title from '../../components/dashboard/Title';
import { useUserContext } from '../../utilities/context/userContext';
import ontime360_mobile from '../../assets/qr_codes/ontime360_mobile.svg';

const Dashboard = () => {
    const authManager = useUserContext();

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    return (
        <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: 450,
                            height: '70vh',
                        }}>
                        <Title>Welcome {authManager.user.userName}</Title>
                        <Typography color='text.secondary'>
                            please download the following apps to get started.
                        </Typography>

                        <Typography sx={{mt: 5}} color='text.secondary'>
                            scan this QR Code for OnTime360 Mobile
                        </Typography>
                        <Box
                            component='img'
                            sx={{
                                // height: '50%', 
                                width: '30%', 
                                minWidth: 200,
                                // maxHeight: 200,
                                // display: 'block',
                                // maxWidth: 400,
                                // overflow: 'hidden',
                                // width: '100%',
                            }}
                            src={ontime360_mobile}
                            alt={'OnTime360 app'}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};
export default Dashboard;
