import React, { useState, useEffect } from 'react';

import { Divider, LinearProgress, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import LocationCards from '../components/LocationCards';
import ceo from '../assets/pics/ceo.jpg';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import parse, { domToReact } from 'html-react-parser';
import SnackbarBase from '../components/SnackbarBase';

const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';
const fSize = 18;

function OpsAlerts({ mode, toggleColorMode }) {
    const { palette } = useTheme();
    const [loading, setLoading] = useState(true);
    const [didError, setDidError] = useState(false);

    const [html, setHtml] = useState('');

    const options = {
        replace(domNode) {
            // let { attribs, children, name } = domNode
            if (!domNode.attribs) {
                return;
            }

            if (domNode.attribs.class !== '') {
                debugger;
                domNode.attribs.class = '';
                domNode.attribs.style = '';
                let thing = {
                    ...domNode,
                    attribs: { ...domNode.attribs, class: '', style: '' },
                };
                // debugger
                // let thing = `<${name}></${name}>`
                // // debugger
                // if (name == 'b') {
                //     return (
                //         <b>
                //             {domToReact(children, options)}
                //         </b>
                //     );
                // }
                return { ...thing };
                // return thing;
                // return domNode.name + {domToReact(children, options)} + domNode.name;
            }
        },
    };

    useEffect(() => {
        const handleGetAlerts = async () => {
            setLoading(true);

            setLoading(true);
            try {
                const response = await fetch('/api/alert', {
                    method: 'get', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    const result = await response.json();
                    setHtml(result[0].content);
                } else {
                    setDidError(true);
                }
            } catch (error) {
                setDidError(true);
                setLoading(false);
            }
            setLoading(false);
        };
        handleGetAlerts();
        const timer = setTimeout(() => {
            setLoading(false);
        }, 600);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <React.Fragment>
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
            <SnackbarBase
                isOpen={didError}
                setIsOpen={setDidError}
                type={'error'}
                message={
                    'there was a problem retrieving the recent alerts. Please try again later'
                }
            />
            <Box sx={{ bgcolor: 'background.default' }}>
                <Box
                    sx={(theme) => ({
                        width: '100%',
                        p: 4,
                        backgroundImage:
                            theme.palette.mode === 'light'
                                ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                                : `linear-gradient(#02294F, ${alpha(
                                      '#090E10',
                                      0.0
                                  )})`,
                        backgroundSize: '100% 20%',
                        backgroundRepeat: 'no-repeat',
                        pt: 15,
                    })}>
                    <Typography
                        component='h2'
                        variant='h4'
                        textAlign='center'
                        color={
                            palette.mode === 'light'
                                ? primary_color
                                : primary_color_dark
                        }
                        sx={{
                            fontSize: 'clamp(3rem, 10vw, 4rem)',
                        }}>
                        Operation Alerts
                    </Typography>
                    <Box sx={{ width: '100%', p: 2 }}>
                        <Container
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                color: 'text.secondary',
                                fontSize: fSize,
                                // alignItems: 'center',
                                gap: { xs: 3, sm: 6 },
                            }}>
                            {loading ? <LinearProgress /> : null}
                            {parse(html, options)}
                        </Container>
                    </Box>
                </Box>
            </Box>
            <Divider />
            <Footer />
        </React.Fragment>
    );
}

export default OpsAlerts;
