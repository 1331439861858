import React, { useContext, createContext, useState, useEffect } from 'react';

const UserContext = createContext();

function ProvideUserContext({ children }) {
    const data = ProvideUser();
    return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
}

function useUserContext() {
    return useContext(UserContext);
}

function ProvideUser() {
    const emptyUser = {
        _id: '',
        email: '',
        userName: '',
        firstName: '',
        lastName: '',
        date: '',
        isAdmin: false,
    };
    const [isAuth, setIsAuth] = useState(false);
    const [user, setUser] = useState(emptyUser);
    const [just_logged_in, set_just_logged_in] = useState(false);
    const [authToken, setAuthToken] = useState(
        localStorage.getItem('site') || ''
    );
    const checkAuth = async () => {
        if (just_logged_in === false) {
            if (authToken.length > 0) {
                try {
                    const response = await fetch('/api/user/reauth', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            authorization: `token ${authToken}`,
                        },
                    });
                    if (!response.ok) {
                        const errorData = await response.json();
                        throw new Error(errorData.message);
                    }
                    const data = await response.json();
                    handleLogin(data);
                    return true;
                } catch (error) {
                    handleLoginOut();
                    console.log(error);
                    return false;
                }
            }
        } else {
            set_just_logged_in(false);
            return true;
        }
    };

    useEffect(() => {
        checkAuth();
        const authTimer = setInterval(() => {
            checkAuth();
        }, 1000 * 60 * 45);
        return () => {
            clearInterval(authTimer);
        };
    }, [authToken]);

    const handleLogin = (res) => {
        const { user, token } = res;
        set_just_logged_in(true);
        setIsAuth(true);
        setUser(user);
        setAuthToken(token);
        localStorage.setItem('site', token);
    };
    const handleLoginOut = () => {
        set_just_logged_in(false);
        setIsAuth(false);
        setUser(emptyUser);
        setAuthToken('');
        localStorage.removeItem('site');
    };

    return {
        isAuth,
        user,
        authToken,
        handleLogin,
        handleLoginOut,
        checkAuth,
    };
}

export { ProvideUserContext, useUserContext };
