import { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Divider, MenuItem, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import TextField from '@mui/material/TextField';
import validator from 'validator';

import { green } from '@mui/material/colors';
import SnackbarBase from '../SnackbarBase';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { useUserContext } from '../../utilities/context/userContext';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';

export default function LocationModal({ refreshHandler = () => {} }) {
    const authManager = useUserContext();

    const { palette } = useTheme();

    const [isSuccess, setIsSuccess] = useState(false);
    const [didError, setDidError] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [open, setOpen] = useState(false);

    const [nameText, setNameText] = useState('');
    const [nameError, setNameError] = useState(false);

    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);

    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(false);

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setNameError(false);
        setSuccess(false);
        setLoading(false);
        setDidError(false);
        setNameText('');
        setPhone('');
        setAddress('');
    };

    const handleSubmit = async () => {
        let hasErrors = false;
        setSuccess(false);
        setLoading(true);

        if (address.length < 10) {
            setAddressError(true);
            hasErrors = true;
        }
        if (phone.length < 8) {
            setPhoneError(true);
            hasErrors = true;
        }
        if (!nameText.length > 0) {
            setNameError(true);
            hasErrors = true;
        }
        if (hasErrors === true) {
            setSuccess(false);
            setLoading(false);
            return;
        } else {
            let data = JSON.stringify({
                title: nameText,
                phone: phone,
                address: address,
            });
            console.log(data);
            try {
                let url = '/api/locations/create';

                const response = await fetch(url, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `token ${authManager.authToken}`,
                    },
                    body: data,
                });

                if (response.ok === true) {
                    const result = await response.json();
                    refreshHandler();
                    console.log('Success:', result);
                    setSuccess(true);
                    setLoading(false);
                    setIsSuccess(true);
                    handleClose();
                } else {
                    setDidError(true);
                    setSuccess(false);
                    setLoading(false);
                }
            } catch (error) {
                debugger;
                setDidError(true);
                setSuccess(false);
                setLoading(false);
                console.error('Error:', error);
            }
        }
    };

    return (
        <div>
            <SnackbarBase
                isOpen={didError}
                setIsOpen={setDidError}
                type={'error'}
                message={
                    'there was a problem submitting your information. Please try again later'
                }
            />
            <SnackbarBase
                isOpen={isSuccess}
                setIsOpen={setIsSuccess}
                type={'success'}
                message={'your info was successfully sent'}
            />
            <Button
                onClick={handleOpen}
                sx={{ my: 2, height: 1 }}
                variant='outlined'
                startIcon={<CorporateFareIcon />}>
                <Typography id='modal-modal-title' variant='p' component='p'>
                    Create Location
                </Typography>
            </Button>

            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}>
                <Fade in={open}>
                    <Box
                        sx={{
                            ...style,
                            width: { xs: '90%', sm: '90%', md: '60%' },
                            borderRadius: 10,
                            borderColor: (theme) => {
                                if (theme.palette.mode === 'light') {
                                    return 'grey.200';
                                }
                                return 'grey.800';
                            },
                        }}>
                        <Typography
                            id='modal-modal-title'
                            variant='h6'
                            component='h2'
                            color={
                                palette.mode === 'light'
                                    ? secondary_color
                                    : secondary_color_dark
                            }>
                            Create Location
                        </Typography>

                        <Divider />
                        <Box sx={{ width: '100%', my: 2 }}>
                            <TextField
                                required
                                error={nameError}
                                id='name'
                                label='Name'
                                variant='standard'
                                helperText={
                                    nameError === true
                                        ? 'Please enter the location name'
                                        : ''
                                }
                                value={nameText}
                                onChange={({ target }) => {
                                    setNameText(target.value);
                                    if (target.value.length > 5) {
                                        setNameError(false);
                                    }
                                }}
                                sx={{ width: '40%' }}
                            />
                            <Divider sx={{ mb: 2 }} />
                            <TextField
                                required
                                error={phoneError}
                                id='phone'
                                label='phone number'
                                variant='standard'
                                helperText={
                                    phoneError === true
                                        ? 'Please enter the phone number'
                                        : ''
                                }
                                value={phone}
                                onChange={({ target }) => {
                                    setPhone(target.value);
                                    if (target.value.length > 5) {
                                        setPhoneError(false);
                                    }
                                }}
                                sx={{ width: '40%' }}
                            />
                            <Divider sx={{ mb: 2 }} />
                            <TextField
                                required
                                error={addressError}
                                id='address'
                                label='address'
                                variant='standard'
                                helperText={
                                    addressError === true
                                        ? 'Please enter the address'
                                        : ''
                                }
                                value={address}
                                onChange={({ target }) => {
                                    setAddress(target.value);
                                    if (target.value.length > 5) {
                                        setAddressError(false);
                                    }
                                }}
                                sx={{ width: '40%' }}
                            />
                            <Typography
                                id='modal-modal-description'
                                sx={{ mt: 4 }}
                                color='text.secondary'>
                                Please enter all required * fields.
                            </Typography>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>
                            <Button onClick={handleClose}>
                                <CloseIcon style={{ color: 'red' }} />
                            </Button>
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button
                                    variant='contained'
                                    sx={buttonSx}
                                    disabled={loading}
                                    // onClick={handleButtonClick}
                                    onClick={handleSubmit}>
                                    Submit
                                    <CheckIcon style={{ color: 'green' }} />
                                </Button>

                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
