import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import NCE_banner from '../assets/NCE_Banner.jpg';
import { useTheme } from '@emotion/react';
import ContactModal from './ContactModal';
const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';

export default function Hero() {
    const { palette } = useTheme();

    return (
        <Box
            id='hero'
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                        : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 2, sm: 10 },
                }}>
                <Box
                    id='image'
                    sx={(theme) => ({
                        mt: { xs: 8, sm: 10 },
                        alignSelf: 'center',
                        height: { xs: 220, sm: 350, md: 500, lg: 800 },
                        position: 'relative',
                        textAlign: 'center',
                        width: '100%',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${NCE_banner})`,
                        backgroundRepeat: 'no-repeat',
                        borderRadius: '10px',
                        outline: '1px solid',
                        outlineColor:
                            theme.palette.mode === 'light'
                                ? alpha('#BFCCD9', 0.5)
                                : alpha('#9CCCFC', 0.1),
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                    })}>
                    <Stack
                        spacing={2}
                        useFlexGap
                        sx={{
                            // width: { xs: '100%', sm: '70%' },
                            height: { xs: 220, sm: 350, md: 500, lg: 800 },
                            display: 'flex',
                            alignSelf: 'center',
                            textAlign: 'center',
                        }}>
                        <Typography
                            variant='h1'
                            // color={primary_color_dark}
                            color={'grey.100'}
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                alignSelf: 'center',
                                textAlign: 'center',
                                fontSize: { xs: 'clamp(1.0rem, 1vw, 2rem)',  sm: 'clamp(1.2rem, 1vw, 2rem)',  md: 'clamp(2.5rem, 1vw, 2rem)' },
                                position: 'absolute',
                                bottom: 40
                            }}>
                            MEDICAL COURIER DELIVERY SOLUTIONS FOR YOUR BUSINESS
                        </Typography>
                        <Typography
                            textAlign='center'
                            // color={secondary_color_dark}
                            color={'grey.100'}
                            sx={{
                                alignSelf: 'center',
                                width: { sm: '100%', md: '80%' },
                                fontSize: { xs: 'clamp(0.7rem, 1vw, 2rem)',  sm: 'clamp(0.9rem, 1vw, 2rem)', md: 'clamp(1.3rem, 1vw, 2rem)' },
                                position: 'absolute',
                                bottom: 10
                            }}>
                            At NCE we offer various rush delivery services to
                            meet your business needs.
                        </Typography>
                    </Stack>
                </Box>

                <ContactModal />
{
                // <Typography
                //     id='About us'
                //     component='h2'
                //     variant='h4'
                //     textAlign='center'
                //     color={
                //         palette.mode === 'light'
                //             ? primary_color
                //             : primary_color_dark
                //     }
                //     sx={{
                //         fontSize: 'clamp(3rem, 10vw, 4rem)',
                //     }}>
                //     About us
                // </Typography>
                // <Typography
                //     textAlign='center'
                //     color='text.secondary'
                //     sx={{
                //         alignSelf: 'center',
                //         width: { sm: '100%', md: '80%' },
                //     }}>
                //     At National Courier Express (NCE), we uphold the belief that
                //     every promise our vendor makes to their client is a promise
                //     we must keep. We don't just see courier delivery as a means
                //     to an end, but as an opportunity to exceed expectations.
                //     When you choose NCE, your package is in the hands of our own
                //     professional and bonded drivers, each with a wealth of
                //     experience spanning over 25 years in medical courier
                //     delivery.
                // </Typography>
                // <Typography
                //     textAlign='center'
                //     color='text.secondary'
                //     sx={{
                //         alignSelf: 'center',
                //         marginTop: 5,
                //         width: { sm: '100%', md: '80%' },
                //     }}>
                //     At NCE, we pride ourselves on our ability to meet even the
                //     most unique needs of organizations such as laboratories,
                //     pharmacies, and medical supplies providers. Our courier
                //     services are not one-size-fits-all but tailored to your
                //     specific requirements. We provide hospitals, labs, and more
                //     with on-demand, same-day, and next-day delivery solutions
                //     for their medical supplies, such as medical specimens,
                //     medical equipment, bloodwork, and pharmaceuticals.
                // </Typography>
                  }
            </Box>
        </Box>
    );
}
