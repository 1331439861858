import * as React from 'react';
import Button from '@mui/material/Button';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import Toolbar from '@mui/material/Toolbar';

import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import NCE_logo from '../assets/NCE_Logo.png';
import NCE_logo_dark from '../assets/NCE_Logo_dark.png';

const logoStyle = {
    width: '200px',
    height: 'auto',
    cursor: 'pointer',
};

export default function DropdownMenu() {
    const navigate = useNavigate();
    const themeColor = useTheme();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(!isOpen);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setIsOpen(false);
    };

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        const offset = 128;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({ behavior: 'smooth' });
            window.scrollTo({
                top: targetScroll,
                behavior: 'smooth',
            });
            // setOpen(false);
        }
    };

    return (
        <Dropdown open={isOpen}>
            <MenuButton
                sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
                onClick={handleClick}>
                <MenuIcon />
            </MenuButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                style={{
                    width: '100%',
                    // top: -30,
                    // zIndex: 200,
                }}>
                <Grid
                    container
                    spacing={1}
                    sx={(theme) => ({
                        // height: '100px',
                        minHeight: '60px',
                        width: '98%',
                        bgcolor:
                            theme.palette.mode === 'light'
                                ? 'rgba(255, 255, 255, 0.4)'
                                : 'rgba(0, 0, 0, 0.4)',
                        backdropFilter: 'blur(24px)',
                        border: '1px solid',
                        borderColor: 'divider',
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                                : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                    })}>
                    {
                    // <Grid item xs={3} md={2}>
                    //     <Button
                    //         onClick={() => {
                    //             scrollToSection('About us');
                    //             handleClose();
                    //         }}>
                    //         <Typography variant='body2' color='text.primary'>
                    //             About us
                    //         </Typography>
                    //     </Button>
                    // </Grid>
                          }
                    <Grid item xs={3} md={2}>
                        <Button
                            onClick={() => {
                                scrollToSection('What we do');
                                handleClose();
                            }}>
                            <Typography variant='body2' color='text.primary'>
                                What we do
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={3} md={2}>
                        <Button
                            onClick={() => {
                                scrollToSection('Our services');
                                handleClose();
                            }}>
                            <Typography variant='body2' color='text.primary'>
                                Our services
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={3} md={2}>
                        <Button
                            onClick={() => {
                                scrollToSection('Why Choose Us');
                                handleClose();
                            }}>
                            <Typography variant='body2' color='text.primary'>
                                Why Choose Us
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Menu>
        </Dropdown>
    );
}

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E6',
    700: '#0059B3',
    800: '#004C99',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};
const MenuButton = styled(BaseMenuButton)(
    ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${
      theme.palette.mode === 'dark'
          ? 'rgba(0, 0, 0, 0.4)'
          : 'rgba(255, 255, 255, 0.4)'
  };
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${
        theme.palette.mode === 'dark' ? blue[300] : blue[200]
    };
    outline: none;
  }
  `
);
