import { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Divider, MenuItem, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import TextField from '@mui/material/TextField';
import validator from 'validator';

import { green } from '@mui/material/colors';
import SnackbarBase from './SnackbarBase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';

export default function ContactModal({buttonType = 'normal'}) {
    const { palette } = useTheme();

    const [isSuccess, setIsSuccess] = useState(false);
    const [didError, setDidError] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [emailData, setEmailData] = useState('');
    const [emailMessage, setEmailMessage] = useState('');

    const [open, setOpen] = useState(false);
    const [textError, setTextError] = useState(false);
    const [message, setMessage] = useState('');

    const [nameText, setNameText] = useState('');
    const [nameError, setNameError] = useState(false);

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setTextError(false);
        setNameError(false);
        setMessage('');
        setNameText('');
        setEmailMessage('');
        setEmailData('');
        setSuccess(false);
        setLoading(false);
        setDidError(false);
    };

    const handleSubmit = async () => {
        let hasErrors = false;
        let isValid = validateEmail(emailData);
        setSuccess(false);
        setLoading(true);

        if (isValid === false) {
            hasErrors = true;
        }
        if (message.length < 20) {
            setTextError(true);
            hasErrors = true;
        }
        if (!nameText.length > 0) {
            setNameError(true);
            hasErrors = true;
        }
        if (hasErrors === true) {
            setSuccess(false);
            setLoading(false);
            return;
        } else {
            let data = JSON.stringify({name: nameText, email: emailData, message: message });
            console.log(data);
            try {
                let url = '/api/inquire';
                if (buttonType === 'appBar-quote') {
                    url = '/api/inquire/quote';
                }
                const response = await fetch(url, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: data,
                });

                const result = await response.json();
                console.log('Success:', result);
                setSuccess(true);
                setLoading(false);
                setIsSuccess(true);
                handleClose();
            } catch (error) {
                debugger;
                setDidError(true);
                setSuccess(false);
                setLoading(false);
                console.error('Error:', error);
            }
        }
    };

    const validateEmail = (email) => {
        setEmailData(email);
        if (validator.isEmail(email)) {
            setEmailMessage('Thank you');
            return true;
        } else {
            setEmailMessage('Please, enter a valid Email');
            return false;
        }
    };

    return (
        <div>
            <SnackbarBase
                isOpen={didError}
                setIsOpen={setDidError}
                type={'error'}
                message={
                    'there was a problem submitting your information. Please try again later'
                }
            />
            <SnackbarBase
                isOpen={isSuccess}
                setIsOpen={setIsSuccess}
                type={'success'}
                message={'your info was successfully sent'}
            />
            {buttonType === 'normal' ?
            <Button onClick={handleOpen} sx={{my: 2}}>
                <Typography
                    id='modal-modal-title'
                    variant='h4'
                    component='h2'
                    color={
                        palette.mode === 'light'
                            ? primary_color
                            : primary_color_dark
                    }>
                    Contact us
                </Typography>
            </Button>
                : null}
                {buttonType === 'appBar' ?
                <MenuItem
                onClick={handleOpen}
                sx={{ py: '6px', px: '12px' }}>
                <Typography
                    variant='body2'
                    color='text.primary'>
                    Contact us
                </Typography>
            </MenuItem>
                : null}
                {buttonType === 'appBar-quote' ?
                    <MenuItem
                    onClick={handleOpen}
                    sx={{ py: '6px', px: '12px' }}>
                    <Typography
                        variant='body2'
                        color='text.primary'>
                        Contact Sales
                    </Typography>
                </MenuItem>
                    : null}
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}>
                <Fade in={open}>
                    <Box
                        sx={{
                            ...style,
                            width: { xs: '90%', sm: '90%', md: '60%' },
                            borderRadius: 10,
                            borderColor: (theme) => {
                                if (theme.palette.mode === 'light') {
                                    return 'grey.200';
                                }
                                return 'grey.800';
                            },
                        }}>
                        <Typography
                            id='modal-modal-title'
                            variant='h6'
                            component='h2'
                            color={
                                palette.mode === 'light'
                                    ? secondary_color
                                    : secondary_color_dark
                            }>
                            {buttonType === 'appBar-quote' ? "Contact Sales" : "Contact us"}
                        </Typography>
                        <Typography
                            id='modal-modal-description'
                            sx={{ mt: 4 }}
                            color='text.secondary'>
                            Please fill out this form and we will be in contact
                            with you soon.
                        </Typography>

                        <Divider />
                        <Box sx={{ width: '100%', my: 2 }}>
                            <TextField
                                required
                                error={nameError}
                                id='name'
                                label='Name'
                                variant='standard'
                                helperText={
                                    nameError === true
                                        ? 'Please enter your name'
                                        : ''
                                }
                                value={nameText}
                                onChange={({ target }) => {
                                    setNameText(target.value)
                                if (target.value.length > 5) {
                                    setNameError(false);
                                }}
                                }
                                sx={{ width: '40%' }}
                            />
                            <Divider sx={{ mb: 2 }} />
                            <TextField
                                required
                                error={
                                    emailMessage !== '' &&
                                    emailMessage !== 'Thank you'
                                        ? true
                                        : false
                                }
                                id='email'
                                label='Email'
                                variant='standard'
                                helperText={emailMessage}
                                onChange={({ target }) =>
                                    validateEmail(target.value)
                                }
                                sx={{ width: '40%' }}
                            />
                            <Divider sx={{ mb: 2 }} />
                            <TextField
                                error={textError}
                                id='message'
                                label='Message'
                                helperText={
                                    textError === true
                                        ? 'please enter a description with at least 20 characters'
                                        : ''
                                }
                                required
                                multiline
                                minRows={4}
                                maxRows={4}
                                value={message}
                                onChange={({ target }) => {
                                    setMessage(target.value);
                                    if (target.value.length < 20) {
                                        setTextError(true);
                                    } else {
                                        setTextError(false);
                                    }
                                }}
                                sx={{ width: '100%' }}
                                variant='standard'
                            />
                            <Typography
                                id='modal-modal-description'
                                sx={{ mt: 4 }}
                                color='text.secondary'>
                                Please enter all required * fields.
                            </Typography>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>
                            <Button onClick={handleClose}>
                                <CloseIcon style={{ color: 'red' }} />
                            </Button>
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button
                                    variant='contained'
                                    sx={buttonSx}
                                    disabled={loading}
                                    // onClick={handleButtonClick}
                                    onClick={handleSubmit}>
                                    Submit
                                    <CheckIcon style={{ color: 'green' }} />
                                </Button>

                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
