import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {
    Divider,
    MenuItem,
    useTheme,
    FormGroup,
    Checkbox,
    TextField,
    FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import validator from 'validator';

import { green } from '@mui/material/colors';
import SnackbarBase from '../SnackbarBase';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TextEditor from './TextEditor';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';

export default function GeneralModal({
    IconButton = () => {
        return <PersonAddIcon />;
    },
    IconButtonEnd = () => {
        return null;
    },
    title = 'open me',
    formFields = [],
    description = 'Please fill out this form.',
    handleSend = async () => {},
}) {
    const { palette } = useTheme();

    const [isSuccess, setIsSuccess] = useState(false);
    const [didError, setDidError] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [open, setOpen] = useState(false);

    const [data, setData] = useState({});
    const [errorData, setErrorData] = useState({});
    const [errorMsg, setErrorMsg] = useState('');

    const handleSetData = (name, value) => {
        let newDataObj = { ...data };
        newDataObj[name] = value;
        setData(newDataObj);
    };

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setErrorMsg('');
        setSuccess(false);
        setLoading(false);
        setDidError(false);
    };
    const validateEmail = (email) => {
        // setEmailData(email);
        handleSetData('email', email);
        if (validator.isEmail(email)) {
            // setEmailMessage('Thank you');
            let errorObj = { ...errorData };
            errorObj.email = false;
            setErrorData(errorObj);
            return true;
        } else {
            let errorObj = { ...errorData };
            errorObj.email = true;
            setErrorData(errorObj);
            // setEmailMessage('Please, enter a valid Email');
            return false;
        }
    };
    const handleSubmit = async () => {
        debugger;
        let hasErrors = false;
        setSuccess(false);
        setLoading(true);
        if (!Object.keys(data).length > 0) {
            debugger;
            hasErrors = true;
            setDidError(true);
        }

        debugger;
        let newErrorObj = {};
        for (const property in data) {
            console.log(`${property}: ${data[property]}`);

            if (!data[property].length > 0 && property !== 'isAdmin') {
                newErrorObj[property] = true;
                hasErrors = true;
            }
            debugger;

            if (property == 'email') {
                debugger;
                let res = validateEmail(data.email);
                let errorObj = { ...errorData };
                errorObj.email = res;
                setErrorData(errorObj);
                debugger;
            }
            debugger;
        }
        setErrorData(newErrorObj);

        if (hasErrors === true) {
            setSuccess(false);
            setLoading(false);
            return;
        } else {
            console.log(data);
            debugger;
            try {
                debugger;
                let res = await handleSend(data);
                debugger;
                if (!res.ok) {
                    const errorData = await res.json();
                    debugger;
                    throw new Error(errorData.message);
                }
                setSuccess(true);
                setLoading(false);
                setIsSuccess(true);
                handleClose();
            } catch (error) {
                debugger;
                setErrorMsg(error.message);
                setDidError(true);
                setSuccess(false);
                setLoading(false);
                console.error('Error:', error);
            }
        }
    };

    useEffect(() => {
        let newObj = {};
        let newErrorObj = {};
        for (let i = 0; i < formFields.length; i++) {
            const field = formFields[i];
            const { name, value } = field;
            newObj[name] = value;
            debugger;
            newErrorObj[name] = false;
        }
        setData(newObj);
        setErrorData(newErrorObj);
        return () => {};
    }, []);

    return (
        <div>
            <SnackbarBase
                isOpen={didError}
                setIsOpen={setDidError}
                type={'error'}
                message={
                    'there was a problem submitting your information. Please try again later'
                }
            />
            <SnackbarBase
                isOpen={isSuccess}
                setIsOpen={setIsSuccess}
                type={'success'}
                message={'your info was successfully sent'}
            />
            <Button
                onClick={handleOpen}
                sx={{ my: 2, height: 1 }}
                variant='outlined'
                startIcon={<IconButton />}
                endIcon={<IconButtonEnd />}>
                <Typography id='modal-modal-title' variant='p' component='p'>
                    {title}
                </Typography>
            </Button>

            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}>
                <Fade in={open}>
                    <Box
                        sx={{
                            ...style,
                            width: { xs: '90%', sm: '90%', md: '60%' },
                            maxHeight: '95vh',
                            borderRadius: 10,
                            borderColor: (theme) => {
                                if (theme.palette.mode === 'light') {
                                    return 'grey.200';
                                }
                                return 'grey.800';
                            },
                        }}>
                        <Typography
                            id='modal-modal-title'
                            variant='h6'
                            component='h2'
                            color={
                                palette.mode === 'light'
                                    ? secondary_color
                                    : secondary_color_dark
                            }>
                            {title}
                        </Typography>
                        <Typography
                            id='modal-modal-description'
                            sx={{ mt: 4 }}
                            color='text.secondary'>
                            {description}
                        </Typography>

                        <Divider />
                        {formFields.map((field) => {
                            const { lable, name, value, type, validText } =
                                field;

                            if (type === 'multiline_text') {
                                return (
                                    <TextField
                                        error={errorData[name]}
                                        id={name}
                                        label={lable}
                                        variant='standard'
                                        helperText={
                                            errorData[name] === true
                                                ? validText
                                                : ''
                                        }
                                        value={data[name]}
                                        required
                                        multiline
                                        minRows={4}
                                        maxRows={4}
                                        onChange={({ target }) => {
                                            handleSetData(name, target.value);
                                            if (target.value.length > 5) {
                                                let errorObj = {
                                                    ...errorData,
                                                };
                                                errorObj[name] = false;
                                                setErrorData(errorObj);
                                            }
                                        }}
                                        sx={{ width: '100%' }}
                                    />
                                );
                            }
                            if (type === 'fancy_text') {
                                return (
                                    <TextEditor
                                        title={'Description'}
                                        description={`Please enter all the job details here.`}
                                        value={data[name]}
                                        maxHeight={'25vh'}
                                        onChange={({ target }) => {
                                            handleSetData(name, target.value);
                                        }}
                                    />
                                );
                            }
                            if (type === 'bool') {
                                return (
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={data[name]}
                                                    onChange={({ target }) => {
                                                        handleSetData(
                                                            name,
                                                            target.checked
                                                        );
                                                    }}
                                                    inputProps={{
                                                        'aria-label':
                                                            'controlled',
                                                    }}
                                                />
                                            }
                                            label={lable}
                                        />
                                    </FormGroup>
                                );
                            } else {
                                return (
                                    <React.Fragment>
                                        <TextField
                                            required
                                            error={errorData[name]}
                                            id={name}
                                            label={lable}
                                            variant='standard'
                                            helperText={
                                                errorData[name] === true
                                                    ? validText
                                                    : ''
                                            }
                                            value={data[name]}
                                            onChange={
                                                type === 'email'
                                                    ? ({ target }) =>
                                                          validateEmail(
                                                              target.value
                                                          )
                                                    : ({ target }) => {
                                                          handleSetData(
                                                              name,
                                                              target.value
                                                          );
                                                          if (
                                                              target.value
                                                                  .length > 5
                                                          ) {
                                                              let errorObj = {
                                                                  ...errorData,
                                                              };
                                                              errorObj[
                                                                  name
                                                              ] = false;
                                                              setErrorData(
                                                                  errorObj
                                                              );
                                                          }
                                                      }
                                            }
                                            sx={{ width: '40%' }}
                                        />
                                        <Divider sx={{ mb: 2 }} />
                                    </React.Fragment>
                                );
                            }
                        })}
                        <Box sx={{ width: '100%', my: 2 }}>
                            <Divider sx={{ mb: 2 }} />
                            <Typography
                                id='modal-modal-description'
                                sx={{ mt: 4 }}
                                color='text.secondary'>
                                Please enter all required * fields.
                            </Typography>
                            {errorMsg.length > 0 ? (
                                <Typography
                                    id='modal-modal-description'
                                    sx={{ mt: 4 }}
                                    color='red'>
                                    {errorMsg}
                                </Typography>
                            ) : null}
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>
                            <Button onClick={handleClose}>
                                <CloseIcon style={{ color: 'red' }} />
                            </Button>
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button
                                    variant='contained'
                                    sx={buttonSx}
                                    disabled={loading}
                                    // onClick={handleButtonClick}
                                    onClick={() => handleSubmit(errorData)}>
                                    Submit
                                    <CheckIcon style={{ color: 'green' }} />
                                </Button>

                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
