import React from 'react';
import { Divider, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Link } from 'react-router-dom';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';

const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';
function UnderWork({ mode, toggleColorMode }) {
    const { palette } = useTheme();

    return (
        <React.Fragment>
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
            <Box sx={{ bgcolor: 'background.default' }}>
                <Box
                    sx={(theme) => ({
                        width: '100%',
                        backgroundImage:
                            theme.palette.mode === 'light'
                                ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                                : `linear-gradient(#02294F, ${alpha(
                                      '#090E10',
                                      0.0
                                  )})`,
                        backgroundSize: '100% 20%',
                        backgroundRepeat: 'no-repeat',
                        pt: 15,
                        minHeight: 660,
                    })}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            pt: { xs: 2, sm: 10 },
                        }}>
                        {
                            // <Typography
                            //     component='h2'
                            //     variant='h4'
                            //     textAlign='center'
                            //     color={
                            //         palette.mode === 'light'
                            //             ? primary_color
                            //             : primary_color_dark
                            //     }
                            //     sx={{
                            //         fontSize: 'clamp(3rem, 10vw, 4rem)',
                            //     }}>
                            //     Coming soon
                            // </Typography>
                        }
                        <EngineeringIcon sx={{ fontSize: 190 }} />
                        <Typography
                            textAlign='center'
                            color='text.secondary'
                            sx={{
                                alignSelf: 'center',
                                width: { sm: '100%', md: '80%' },
                            }}>
                            Sorry, this section of the website is still under
                            development. Please come back soon.
                        </Typography>

                        <Link
                            to='/'
                            style={{
                                color:
                                    palette.mode === 'light'
                                        ? primary_color
                                        : primary_color_dark,

                                fontSize: 30,
                            }}>
                            Back to Home
                        </Link>
                    </Box>
                </Box>
            </Box>

            <Divider />
            <Footer />
        </React.Fragment>
    );
}

export default UnderWork;
