import React, { useState } from 'react';
import {
    Editor,
    EditorProvider,
    Toolbar,
    BtnUndo,
    BtnRedo,
    BtnBold,
    BtnItalic,
    BtnUnderline,
    BtnStrikeThrough,
    BtnNumberedList,
    BtnBulletList,
    BtnLink,
    BtnClearFormatting,
    HtmlButton,
    Separator,
    BtnStyles,
} from 'react-simple-wysiwyg';
import { useTheme } from '@mui/material/styles';
import Title from './Title';
import { Box, Typography } from '@mui/material';

const TextEditor = ({ title, description, onChange, value, maxHeight = '80%' }) => {


    const theme = useTheme();

    return (
        <React.Fragment>
            <Title>{title}</Title>
            <Typography color='text.secondary'>{description}</Typography>
            <Box
                component={'div'}
                sx={{ width: '100%', flexGrow: 1, overflow: 'hidden', mt: 2 }}>
                {
                    // <Editor containerProps={{ style: { resize: 'vertical', minHeight: 240, maxHeight: '90%', overflowY: 'auto' } }} value={html} onChange={onChange}/>
                }
                <EditorProvider>
                    <Toolbar>
                        <BtnUndo />
                        <BtnRedo />
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnStrikeThrough />
                        <BtnNumberedList />
                        <BtnBulletList />
                        <BtnLink />
                        <BtnClearFormatting />
                        <HtmlButton />
                        <Separator />
                        <BtnStyles />
                    </Toolbar>
                    <Editor
                        value={value}
                        onChange={onChange}
                        containerProps={{
                            style: {
                                resize: 'vertical',
                                // minHeight: 240, 
                                height: '70%',
                                maxHeight: maxHeight,
                                overflowY: 'auto',
                            },
                        }}></Editor>
                </EditorProvider>
            </Box>
        </React.Fragment>
    );
};

export default TextEditor;
