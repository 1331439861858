import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from './ToggleColorMode';
import { useNavigate, useLocation } from 'react-router-dom';

import NCE_logo from '../assets/NCE_Logo.png';
import NCE_logo_dark from '../assets/NCE_Logo_dark.png';
import DropdownMenu from './DropdownMenu';
import ContactModal from './ContactModal';

const logoStyle = {
    width: '200px',
    height: 'auto',
    cursor: 'pointer',
};

function AppAppBar({ mode, toggleColorMode }) {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const themeColor = useTheme();

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        const offset = 128;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({ behavior: 'smooth' });
            window.scrollTo({
                top: targetScroll,
                behavior: 'smooth',
            });
            setOpen(false);
        }
    };

    return (
        <div>
            <AppBar
                position='fixed'
                sx={{
                    boxShadow: 0,
                    bgcolor: 'transparent',
                    backgroundImage: 'none',
                    mt: 2,
                }}>
                <Box>
                    <Toolbar
                        variant='regular'
                        sx={(theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexShrink: 0,
                            borderRadius: '999px',
                            bgcolor:
                                theme.palette.mode === 'light'
                                    ? 'rgba(255, 255, 255, 0.4)'
                                    : 'rgba(0, 0, 0, 0.4)',
                            backdropFilter: 'blur(24px)',
                            maxHeight: 40,
                            border: '1px solid',
                            borderColor: 'divider',
                            boxShadow:
                                theme.palette.mode === 'light'
                                    ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                                    : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                        })}>
                        <Box
                            // onClick={() => navigate('/')}
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'center',
                                // ml: '-18px',
                                px: 0,
                            }}>
                            {location.pathname === '/' ? (
                                <DropdownMenu />
                            ) : null}
                            <MenuItem onClick={() => navigate('/')}>
                                <img
                                    src={
                                        themeColor.palette.mode === 'light'
                                            ? NCE_logo
                                            : NCE_logo_dark
                                    }
                                    style={logoStyle}
                                    alt='logo of NCE'
                                />
                            </MenuItem>
                            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' } }}>
                            <ContactModal buttonType='appBar-quote' />
                            <ContactModal buttonType='appBar' />
                            <MenuItem
                            onClick={() => navigate('/about-us')}
                            sx={{ py: '6px', px: '12px' }}>
                            <Typography
                                variant='body2'
                                color='text.primary'>
                                About us
                            </Typography>
                        </MenuItem>
                                <MenuItem
                                    onClick={() => navigate('/locations')}
                                    sx={{ py: '6px', px: '12px' }}>
                                    <Typography
                                        variant='body2'
                                        color='text.primary'>
                                        Locations
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        navigate('/operational-alerts')
                                    }
                                    sx={{ py: '6px', px: '12px' }}>
                                    <Typography
                                        variant='body2'
                                        color='text.primary'>
                                        Operational alerts
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        navigate('/track-your-delivery')
                                    }
                                    sx={{ py: '6px', px: '12px' }}>
                                    <Typography
                                        variant='body2'
                                        color='text.primary'>
                                        Track your delivery
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => navigate('/careers')}
                                    sx={{ py: '6px', px: '12px' }}>
                                    <Typography
                                        variant='body2'
                                        color='text.primary'>
                                        Careers
                                    </Typography>
                                </MenuItem>

                                {
                                    // <MenuItem
                                    //     onClick={() => scrollToSection('About us')}
                                    //     sx={{ py: '6px', px: '12px' }}>
                                    //     <Typography
                                    //         variant='body2'
                                    //         color='text.primary'>
                                    //         About us
                                    //     </Typography>
                                    // </MenuItem>
                                    // <MenuItem
                                    //     onClick={() =>
                                    //         scrollToSection('What we do')
                                    //     }
                                    //     sx={{ py: '6px', px: '12px' }}>
                                    //     <Typography
                                    //         variant='body2'
                                    //         color='text.primary'>
                                    //         What we do
                                    //     </Typography>
                                    // </MenuItem>
                                    // <MenuItem
                                    //     onClick={() =>
                                    //         scrollToSection('Our services')
                                    //     }
                                    //     sx={{ py: '6px', px: '12px' }}>
                                    //     <Typography
                                    //         variant='body2'
                                    //         color='text.primary'>
                                    //         Our services
                                    //     </Typography>
                                    // </MenuItem>
                                    // <MenuItem
                                    //     onClick={() =>
                                    //         scrollToSection('Why Choose Us')
                                    //     }
                                    //     sx={{ py: '6px', px: '12px' }}>
                                    //     <Typography
                                    //         variant='body2'
                                    //         color='text.primary'>
                                    //         Why Choose Us
                                    //     </Typography>
                                    // </MenuItem>
                                }
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: { xs: 'none', sm: 'none',  md: 'flex', lg: 'flex' },
                                gap: 0.5,
                                alignItems: 'center',
                            }}>
                            <ToggleColorMode
                                mode={mode}
                                toggleColorMode={toggleColorMode}
                            />
                            {
                                // <Button
                                //   color="primary"
                                //   variant="text"
                                //   size="small"
                                //   component="a"
                                //   href="/material-ui/getting-started/templates/sign-in/"
                                //   target="_blank"
                                // >
                                //   Sign in
                                // </Button>
                                // <Button
                                //   color="primary"
                                //   variant="contained"
                                //   size="small"
                                //   component="a"
                                //   href="/material-ui/getting-started/templates/sign-up/"
                                //   target="_blank"
                                // >
                                //   Sign up
                                // </Button>
                            }
                        </Box>
                        <Box sx={{ display: { sm: '', md: '', lg: 'none' } }}>
                            <Button
                                variant='text'
                                color='primary'
                                aria-label='menu'
                                onClick={toggleDrawer(true)}
                                sx={{ minWidth: '30px', p: '4px' }}>
                                <MenuIcon />
                            </Button>
                            <Drawer
                                anchor='right'
                                open={open}
                                onClose={toggleDrawer(false)}>
                                <Box
                                    sx={{
                                        minWidth: '60dvw',
                                        p: 2,
                                        backgroundColor: 'background.paper',
                                        flexGrow: 1,
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'end',
                                            flexGrow: 1,
                                        }}>
                                        <ToggleColorMode
                                            mode={mode}
                                            toggleColorMode={toggleColorMode}
                                        />
                                    </Box>
                                    <MenuItem
                                        onClick={() =>
                                          navigate('/')
                                        }>
                                        Home
                                    </MenuItem>
                                    <ContactModal buttonType='appBar-quote' />
                                    <ContactModal buttonType='appBar' />
                                    <MenuItem
                                        onClick={() =>
                                          navigate('/about-us')
                                        }>
                                        About us
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                          navigate('/locations')
                                        }>
                                        Locations
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                          navigate('/operational-alerts')
                                        }>
                                        Operational alerts
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                          navigate('/track-your-delivery')
                                        }>
                                        Track your delivery
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                          navigate('/careers')
                                        }>
                                        Careers
                                    </MenuItem>
                                    <Divider />

                                    {location.pathname === '/' ? (
                                        <React.Fragment>
                                            <MenuItem
                                                onClick={() =>
                                                    scrollToSection('About us')
                                                }>
                                                About us
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() =>
                                                    scrollToSection(
                                                        'What we do'
                                                    )
                                                }>
                                                What we do
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() =>
                                                    scrollToSection(
                                                        'Our services'
                                                    )
                                                }>
                                                Our services
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() =>
                                                    scrollToSection(
                                                        'Why Choose Us'
                                                    )
                                                }>
                                                Why Choose Us
                                            </MenuItem>
                                        </React.Fragment>
                                    ) : null}
                                    {
                                        //   <MenuItem>
                                        //   <Button
                                        //     color="primary"
                                        //     variant="contained"
                                        //     component="a"
                                        //     href="/material-ui/getting-started/templates/sign-up/"
                                        //     target="_blank"
                                        //     sx={{ width: '100%' }}
                                        //   >
                                        //     Sign up
                                        //   </Button>
                                        // </MenuItem>
                                        // <MenuItem>
                                        //   <Button
                                        //     color="primary"
                                        //     variant="outlined"
                                        //     component="a"
                                        //     href="/material-ui/getting-started/templates/sign-in/"
                                        //     target="_blank"
                                        //     sx={{ width: '100%' }}
                                        //   >
                                        //     Sign in
                                        //   </Button>
                                        // </MenuItem>
                                    }
                                </Box>
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Box>
            </AppBar>
        </div>
    );
}

AppAppBar.propTypes = {
    mode: PropTypes.oneOf(['dark', 'light']).isRequired,
    toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
