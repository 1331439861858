import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LogoBar from './LogoBar';
import { useTheme } from '@mui/material';
import QuotesBar from './QuotesBar';

const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';

export default function OurPartners() {
    const { palette } = useTheme();

    return (
        <Box
            id='OurPartners'
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                color: 'white',
                // bgcolor: '#06090a',
            }}>
            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}>
                <Box
                    sx={{
                        width: { sm: '100%', md: '100%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}>
                    <Typography
                        component='h2'
                        variant='h4'
                        color={
                            palette.mode === 'light'
                                ? primary_color
                                : primary_color_dark
                        }
                        mb={4}>
                        Our Partners
                    </Typography>
                    {
                        // <Typography variant='body1' color='text.secondary'>
                        // </Typography>
                    }
                    <Box
                    width={'100%'}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'>
                        <LogoBar />
                    </Box>

                </Box>
            </Container>
        </Box>
    );
}
