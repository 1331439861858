import React, { useState } from 'react';
import { alpha } from '@mui/material';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Button,
    Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@emotion/react';
import { green } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import validator from 'validator';
import { useUserContext } from '../../utilities/context/userContext';
import { useNavigate } from 'react-router-dom';
import AppAppBar from '../../components/AppAppBar';
import Footer from '../../components/Footer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';
function Login({ mode, toggleColorMode }) {
    const navigate = useNavigate();
    const authManager = useUserContext();
    const { palette } = useTheme();

    const [isSuccess, setIsSuccess] = useState(false);
    const [didError, setDidError] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [emailData, setEmailData] = useState('');
    const [emailMessage, setEmailMessage] = useState('');

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const handleClose = () => {
        setPasswordError(false);
        setEmailMessage('');
        setEmailData('');
        setSuccess(false);
        setLoading(false);
        setDidError(false);
    };

    const handleSubmit = async () => {
        let hasErrors = false;
        let isValid = validateEmail(emailData);
        setSuccess(false);
        setLoading(true);

        if (isValid === false) {
            hasErrors = true;
        }

        if (!password.length > 0) {
            setPasswordError(true);
            hasErrors = true;
        }
        if (hasErrors === true) {
            setSuccess(false);
            setLoading(false);
            return;
        } else {
            let data = JSON.stringify({ password: password, email: emailData });
            try {
                let url = '/api/user/login';
                const response = await fetch(url, {
                    method: 'POST', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: data,
                });

                const result = await response.json();
                console.log('Success:', result);
                authManager.handleLogin(result);
                setSuccess(true);
                setLoading(false);
                setIsSuccess(true);
                handleClose();

                if (result.message === 'success') {
                    navigate('/dashboard');
                } else {
                    setDidError(true);
                }
            } catch (error) {
                debugger;
                setDidError(true);
                setSuccess(false);
                setLoading(false);
                console.error('Error:', error);
            }
        }
    };

    const validateEmail = (email) => {
        setEmailData(email);
        if (validator.isEmail(email)) {
            setEmailMessage('Thank you');
            return true;
        } else {
            setEmailMessage('Please, enter a valid Email');
            return false;
        }
    };

    return (
        <React.Fragment>
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />

            <Box sx={{ bgcolor: 'background.default' }}>
                <Box
                    sx={(theme) => ({
                        width: '100%',
                        backgroundImage:
                            theme.palette.mode === 'light'
                                ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                                : `linear-gradient(#02294F, ${alpha(
                                      '#090E10',
                                      0.0
                                  )})`,
                        backgroundSize: '100% 20%',
                        backgroundRepeat: 'no-repeat',
                        pt: 15,
                        minHeight: 800,
                    })}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            pt: { xs: 2, sm: 10 },
                        }}>
                        <Box
                            sx={{
                                width: '100%',
                                maxWidth: {
                                    xs: 460,
                                    sm: 660,
                                    md: 760,
                                    lg: 860,
                                },
                                p: 1,
                                bgcolor: 'background.paper',
                                border: 1,
                                borderRadius: 5,
                                borderColor: (theme) => {
                                    if (theme.palette.mode === 'light') {
                                        return 'grey.200';
                                    }
                                    return 'grey.800';
                                },
                            }}>
                            <Typography
                                style={{
                                    color:
                                        palette.mode === 'light'
                                            ? primary_color
                                            : primary_color_dark,

                                    fontSize: 24,
                                    textAlign: 'center',
                                }}>
                                Login
                            </Typography>
                            <Divider />
                            <Box sx={{ width: '100%', my: 2 }}>
                                <TextField
                                    required
                                    error={
                                        emailMessage !== '' &&
                                        emailMessage !== 'Thank you'
                                            ? true
                                            : false
                                    }
                                    id='email'
                                    label='Email'
                                    variant='standard'
                                    type='email'
                                    autoComplete='current-email'
                                    helperText={emailMessage}
                                    onChange={({ target }) =>
                                        validateEmail(target.value)
                                    }
                                    sx={{ width: '40%' }}
                                />
                                <Divider sx={{ mb: 2 }} />
                                <TextField
                                    required
                                    error={passwordError}
                                    id='password'
                                    label='password'
                                    variant='standard'
                                    type='password'
                                    autoComplete='current-password'
                                    helperText={
                                        passwordError === true
                                            ? 'Please enter your password'
                                            : ''
                                    }
                                    value={password}
                                    onChange={({ target }) => {
                                        setPassword(target.value);
                                        if (target.value.length > 5) {
                                            setPasswordError(false);
                                        }
                                    }}
                                    sx={{ width: '40%' }}
                                />
                                <Typography
                                    id='modal-modal-description'
                                    sx={{ mt: 4 }}
                                    color='text.secondary'>
                                    Please enter all required * fields.
                                </Typography>

                                {didError === true ? (
                                    <Typography
                                        id='modal-modal-description'
                                        sx={{ mt: 4 }}
                                        color='red'>
                                        Please check your email and password.
                                    </Typography>
                                ) : null}
                            </Box>
                            <Divider />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}>
                                <Box sx={{ m: 1, position: 'relative' }}>
                                    <Button
                                        variant='contained'
                                        sx={buttonSx}
                                        disabled={loading}
                                        // onClick={handleButtonClick}
                                        onClick={handleSubmit}>
                                        Login
                                        <CheckIcon style={{ color: 'green' }} />
                                    </Button>

                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: green[500],
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Divider />
            <Footer />
        </React.Fragment>
    );
}

export default Login;
