import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LayersIcon from '@mui/icons-material/Layers';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkIcon from '@mui/icons-material/Work';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../utilities/context/userContext';
export const MainListItems = () => {
    const navigate = useNavigate();
    const authManager = useUserContext();
    return (
        <React.Fragment>
            <ListItemButton onClick={() => navigate('/dashboard')}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary='Dashboard' />
            </ListItemButton>
            {authManager.user.isAdmin === true ? (
                <React.Fragment>
                    <ListItemButton
                        onClick={() =>
                            navigate('/dashboard/account–management')
                        }>
                        <ListItemIcon>
                            <ManageAccountsIcon />
                        </ListItemIcon>
                        <ListItemText primary='Manage accounts' />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() =>
                            navigate('/dashboard/operational-alerts')
                        }>
                        <ListItemIcon>
                            <AnnouncementIcon />
                        </ListItemIcon>
                        <ListItemText primary='Operational alerts' />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => navigate('/dashboard/job-postings')}>
                        <ListItemIcon>
                            <WorkIcon />
                        </ListItemIcon>
                        <ListItemText primary='Job postings' />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => navigate('/dashboard/locations')}>
                        <ListItemIcon>
                            <CorporateFareIcon />
                        </ListItemIcon>
                        <ListItemText primary='Locations' />
                    </ListItemButton>
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
};

export const SecondaryListItems = () => {
    const navigate = useNavigate();
    const authManager = useUserContext();
    return (
        <React.Fragment>
            <ListItemButton onClick={() => navigate('/dashboard/account')}>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary='Accounts settings' />
            </ListItemButton>
            <ListItemButton
                onClick={() => {
                    authManager.handleLoginOut();
                }}>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary='Logout' />
            </ListItemButton>
        </React.Fragment>
    );
};
