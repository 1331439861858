import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import parse, { domToReact } from 'html-react-parser';

const bull = (
    <Box
        component='span'
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
        •
    </Box>
);

export default function CareerCard({
    title,
    location,
    rate,
    description,
    date,
    data,
    handleChange = () => {},
}) {
    const options = {
        replace(domNode) {
            // let { attribs, children, name } = domNode
            if (!domNode.attribs) {
                return;
            }

            if (domNode.attribs.class !== '') {
                debugger;
                domNode.attribs.class = '';
                domNode.attribs.style = '';
                let thing = {
                    ...domNode,
                    attribs: { ...domNode.attribs, class: '', style: '' },
                };
                return { ...thing };
                // return thing;
                // return domNode.name + {domToReact(children, options)} + domNode.name;
            }
        },
    };

    return (
        <Card sx={{ minWidth: 275, my: 2 }}>
            <CardContent>
                <Typography variant='h5' component='div'>
                    {title}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                    location: {location}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                    rate: {rate}
                </Typography>
                <Box sx={{ width: '100%', p: 2 }}>
                    {parse(description.substring(0, 230) + '......', options)}
                </Box>
            </CardContent>
            <CardActions>
                <Button
                    size='small'
                    onClick={() => {
                        handleChange();
                    }}>
                    Learn More
                </Button>
            </CardActions>
        </Card>
    );
}
