import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const quotes = [
    {
        quote: ` “We are what we repeatedly do. Excellence,
        then, is not an act, but a habit.”`,
        author: '—Aristotle',
    },
    {
        quote: `“When you give joy to other people, you get more joy in return.
        You should give a good thought to happiness that you can give out.”`,
        author: '—Eleanor Roosevelt',
    },{
        quote: `“The pessimist sees difficulty in every opportunity.
         The optimist sees opportunity in every difficulty.”`,
        author: `—Winston Churchill`,
    },{
        quote: `“Ability is what you’re capable of doing. Motivation determines what you do.
        Attitude determines how well you do it.”`,
        author: '—Lou Holtz',
    },
];

function QuotesBar() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = quotes.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ maxWidth: {xs: '100%', sm: '95%', md: '80%' }, flexGrow: 1 }}>
            <AutoPlaySwipeableViews
            interval={5000}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents>
                {quotes.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                sx={{
                                    // height: 255,
                                    minHeight: 200,
                                    maxHeight: {xs: 320, sm: 220, md: 200 },
                                    display: 'block',
                                    // minWidth: 400,
                                    overflow: 'hidden',
                                    width: '100%',
                                    padding: 5,
                                }}>
                                <Typography
                                fontSize={20}
                                    variant='body1'
                                    color='text.secondary'>
                                    {step.quote}
                                </Typography>
                                <Typography
                                fontSize={20}
                                    variant='body1'
                                    color='text.secondary'
                                    fontWeight={'bold'}
                                    >
                                    {step.author}
                                </Typography>
                            </Box>
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position='static'
                activeStep={activeStep}
                nextButton={
                    <Button
                        size='small'
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button
                        size='small'
                        onClick={handleBack}
                        disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                    </Button>
                }
            />
        </Box>
    );
}

export default QuotesBar;
