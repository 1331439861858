import React, { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextEditor from '../../components/dashboard/TextEditor';
import { Button, Box, CircularProgress, Divider } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { green } from '@mui/material/colors';
import parse, { domToReact } from 'html-react-parser';
import { useUserContext } from '../../utilities/context/userContext';
import SnackbarBase from '../../components/SnackbarBase';
import RefreshIcon from '@mui/icons-material/Refresh';

const Alerts = () => {
    const authManager = useUserContext();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [didError, setDidError] = useState(false);

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const [html, setHtml] = useState('Enter Text Here!');
    const handleGetAlerts = async () => {
        setLoading(true);

        setLoading(true);
        try {
            const response = await fetch('/api/alert', {
                method: 'get', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                const result = await response.json();
                setHtml(result[0].content);
            } else {
                setDidError(true);
            }
        } catch (error) {
            setDidError(true);
            setLoading(false);
        }
        setLoading(false);
    };
    useEffect(() => {

        handleGetAlerts();
        const timer = setTimeout(() => {
            setLoading(false);
        }, 600);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    function onChange(e) {
        setHtml(e.target.value);
    }

    const handleSubmit = async () => {
        setLoading(true);

        setLoading(true);
        try {
            let data = { content: html, postedBy: authManager.user._id };
            const response = await fetch('/api/alert/create', {
                method: 'post', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `token ${authManager.authToken}`,
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                const result = await response.json();
                // setUsers(result);
                setSuccess(true)
            } else {
                setDidError(true);
            }
        } catch (error) {
            setDidError(true);
            setLoading(false);
        }
        setLoading(false);
    };

    const options = {
        replace(domNode) {
            // let { attribs, children, name } = domNode
            if (!domNode.attribs) {
                return;
            }

            if (domNode.attribs.class !== '') {
                debugger;
                domNode.attribs.class = '';
                domNode.attribs.style = '';
                let thing = {
                    ...domNode,
                    attribs: { ...domNode.attribs, class: '', style: '' },
                };
                // debugger
                // let thing = `<${name}></${name}>`
                // // debugger
                // if (name == 'b') {
                //     return (
                //         <b>
                //             {domToReact(children, options)}
                //         </b>
                //     );
                // }
                return { ...thing };
                // return thing;
                // return domNode.name + {domToReact(children, options)} + domNode.name;
            }
        },
    };

    return (
        <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <SnackbarBase
        isOpen={didError}
        setIsOpen={setDidError}
        type={'error'}
        message={
            'there was a problem submitting the data. Please try again later'
        }
    />
    <SnackbarBase
        isOpen={success}
        setIsOpen={setSuccess}
        type={'success'}
        message={
            'data Submission was successful'
        }
    />
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: 450,
                            height: '70vh',
                        }}>
                        
                        <TextEditor
                            title={'Alert content'}
                            description={`please enter the text you would like to show on the page here.`}
                            value={html}
                            onChange={onChange}
                        />
                        <Divider />
                        <Button onClick={handleGetAlerts} variant='outlined' sx={{ maxWidth: 4, m: 2}} startIcon={<RefreshIcon />}> </Button>
            
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Button
                                variant='contained'
                                sx={buttonSx}
                                disabled={loading}
                                onClick={handleSubmit}>
                                Submit
                                {!loading && (
                                    <CheckIcon style={{ color: 'green' }} />
                                )}
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: green[500],
                                            // position: 'absolute',
                                            // top: '50%',
                                            // left: '5%',
                                            // marginTop: '-12px',
                                            marginLeft: '12px',
                                        }}
                                    />
                                )}
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};
export default Alerts;
