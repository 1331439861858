import React, { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { Box, Button, Divider, Grid, LinearProgress } from '@mui/material';
import { useUserContext } from '../../utilities/context/userContext';
import SnackbarBase from '../SnackbarBase';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import GeneralModal from './GeneralModal';
import { useNavigate } from 'react-router-dom';

export default function Users() {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [didError, setDidError] = useState(false);

    const authManager = useUserContext();
    const navigate = useNavigate();



    const handleDelete = async (user) => {
        setLoading(true);
        try {
            const response = await fetch(`/api/user/${user._id}`, {
                method: 'delete', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `token ${authManager.authToken}`,
                },
            });
            if (response.ok) {
                const result = await response.json();
                if (result.acknowledged === true) {
                    // handleGetUsers();

                    navigate('/login')
                }
            } else {
                setDidError(true);
            }
        } catch (error) {
            setDidError(true);
            setLoading(false);
        }
        setLoading(false);
    };
    debugger

    return (
        <React.Fragment>
            <SnackbarBase
                isOpen={didError}
                setIsOpen={setDidError}
                type={'error'}
                message={
                    'there was a problem retrieving user information. Please try again later'
                }
            />
            <Title>User Settings</Title>
            <Divider />
            <Grid container>
                <Grid
                    item
                    xs={10}
                    style={{
                        display: 'flex',
                        gap: '1rem',
                        alignItems: 'center',
                    }}>
                    <GeneralModal
                        IconButton={() => null}
                        formFields={[
                            {
                                lable: 'Username',
                                name: 'userName',
                                value: authManager.user.userName,
                                type: 'text',
                                validText: 'please enter the username',
                            },
                            {
                                lable: 'Firstname',
                                name: 'firstName',
                                value: authManager.user.firstName,
                                type: 'text',
                                validText: 'please enter the first name',
                            },
                            {
                                lable: 'Lastname',
                                name: 'lastName',
                                value: authManager.user.lastName,
                                type: 'text',
                                validText: 'please enter the last name',
                            },
                            {
                                lable: 'Email',
                                name: 'email',
                                value: authManager.user.email,
                                type: 'email',
                                validText: 'please enter a valid email',
                            },
                        ]}
                        title='Change user info'
                        handleSend={async (data) => {
                            debugger;
                            // return await handlePasswordSubmit(data, user);

                            const response = await fetch(
                                `/api/user/${authManager.user._id}`,
                                {
                                    method: 'PUT',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        authorization: `token ${authManager.authToken}`,
                                    },
                                    body: JSON.stringify(data),
                                }
                            );
                            debugger;
                            if (!response.ok) {
                                return response;
                            }
                            const resData = await response.json();
                            authManager.handleLogin(resData);
                            debugger;
                            return response;

                            // } catch (error) {
                            //   debugger
                            //     // authManager.handleLoginOut();
                            //     console.log(error);
                            // }
                        }}
                    />
                    <GeneralModal
                        IconButton={() => null}
                        formFields={[
                            {
                                lable: 'Password',
                                name: 'password',
                                value: '',
                                type: 'text',
                                validText: 'please select a valid password',
                            },
                        ]}
                        title='Reset Password'
                        handleSend={async (data) => {
                            debugger;
                            // return await handlePasswordSubmit(data, user);

                            const response = await fetch(
                                `/api/user/${authManager.user._id}`,
                                {
                                    method: 'PUT',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        authorization: `token ${authManager.authToken}`,
                                    },
                                    body: JSON.stringify(data),
                                }
                            );
                            debugger;
                            if (!response.ok) {
                                return response;
                            }
                            const resData = await response.json();
                            authManager.handleLogin(resData);
                            debugger;
                            return response;

                            // } catch (error) {
                            //   debugger
                            //     // authManager.handleLoginOut();
                            //     console.log(error);
                            // }
                        }}
                    />
                    <Button
                        onClick={() => {
                            handleDelete(authManager.user);
                        }}
                        variant='outlined'
                        color='error'
                        startIcon={<DeleteIcon />}>
                        Delete
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
