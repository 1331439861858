import React, { useState, useEffect } from 'react';
import { Button, Container, Grid, Paper, alpha } from '@mui/material';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    LinearProgress,
    ListItemText,
    Divider,
} from '@mui/material';
import parse, { domToReact } from 'html-react-parser';
import { useTheme } from '@emotion/react';
import jobDoc from '../assets/docs/Job_requisition.docx.pdf';
import Footer from '../components/Footer';
import AppAppBar from '../components/AppAppBar';
import CareerCard from '../components/CareerCard';
import Title from '../components/dashboard/Title';
import GeneralModal from '../components/dashboard/GeneralModal';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useUserContext } from '../utilities/context/userContext';


const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';
function Careers({ mode, toggleColorMode }) {
    const { palette } = useTheme();
    const authManager = useUserContext();
    const [loading, setLoading] = useState(true);
    const [didError, setDidError] = useState(false);
    const [jobPosts, setJobPosts] = useState([]);
    const [postShown, setPostShown] = useState(false);
    const [postData, setPostData] = useState({});

    const formFields = [
    {
        lable: 'Name',
        name: 'name',
        value: '',
        type: 'text',
        validText: 'please enter your name',
    },
    {
        lable: 'Email',
        name: 'email',
        value: '',
        type: 'email',
        validText: 'please enter a valid email',
    },{
        lable: 'Message',
        name: 'message',
        value: '',
        type: 'multiline_text',
        validText: 'please enter a message',
    },]

    useEffect(() => {
        const handleGetAlerts = async () => {
            setLoading(true);

            setLoading(true);
            try {
                const response = await fetch('/api/jobposts', {
                    method: 'get', // or 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    const result = await response.json();
                    setJobPosts(result);
                } else {
                    setDidError(true);
                }
            } catch (error) {
                setDidError(true);
                setLoading(false);
            }
            setLoading(false);
        };
        handleGetAlerts();
        const timer = setTimeout(() => {
            setLoading(false);
        }, 600);
        return () => {
            clearTimeout(timer);
        };
    }, []);
    const options = {
        replace(domNode) {
            // let { attribs, children, name } = domNode
            if (!domNode.attribs) {
                return;
            }

            if (domNode.attribs.class !== '') {
                debugger;
                domNode.attribs.class = '';
                domNode.attribs.style = '';
                let thing = {
                    ...domNode,
                    attribs: { ...domNode.attribs, class: '', style: '' },
                };
                return { ...thing };
                // return thing;
                // return domNode.name + {domToReact(children, options)} + domNode.name;
            }
        },
    };
    return (
        <React.Fragment>
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
            <Box sx={{ bgcolor: 'background.default' }}>
                <Box
                    sx={(theme) => ({
                        width: '100%',
                        backgroundImage:
                            theme.palette.mode === 'light'
                                ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                                : `linear-gradient(#02294F, ${alpha(
                                      '#090E10',
                                      0.0
                                  )})`,
                        backgroundSize: '100% 20%',
                        backgroundRepeat: 'no-repeat',
                        pt: 15,
                        minHeight: 800,
                    })}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            pt: { xs: 2, sm: 10 },
                        }}>
                        {
                            // <Typography
                            //     component='h2'
                            //     variant='h4'
                            //     textAlign='center'
                            //     color={
                            //         palette.mode === 'light'
                            //             ? primary_color
                            //             : primary_color_dark
                            //     }
                            //     sx={{
                            //         fontSize: 'clamp(3rem, 10vw, 4rem)',
                            //     }}>
                            //     Coming soon
                            // </Typography>
                        }

                        {postShown !== true ? 
                        <React.Fragment>
                        <Typography
                            textAlign='center'
                            color='text.secondary'
                            sx={{
                                alignSelf: 'center',
                                width: { sm: '100%', md: '80%' },
                            }}>
                            Please check out our current job postings and check
                            back for updates soon.
                        </Typography>
                        <Box
                            sx={{
                                width: '100%',
                                maxWidth: {
                                    xs: 460,
                                    sm: 660,
                                    md: 760,
                                    lg: 860,
                                },
                                p: 1,
                                my: 4,
                                bgcolor: 'background.paper',
                                border: 1,
                                borderRadius: 5,
                                borderColor: (theme) => {
                                    if (theme.palette.mode === 'light') {
                                        return 'grey.200';
                                    }
                                    return 'grey.800';
                                },
                            }}>
                            <Typography
                                style={{
                                    color:
                                        palette.mode === 'light'
                                            ? primary_color
                                            : primary_color_dark,

                                    fontSize: 24,
                                }}>
                                Job postings
                            </Typography>
                            <Divider />
                            {loading ? <LinearProgress /> : null}
                            <Divider />

                            <Box>
                                {jobPosts.map((jobData) => {
                                    const {
                                        title,
                                        location,
                                        rate,
                                        description,
                                        date,
                                    } = jobData;

                                    return (
                                        <CareerCard
                                            title={title}
                                            location={location}
                                            rate={rate}
                                            description={description}
                                            date={date}
                                            data={jobData}
                                            handleChange={()=> {
                                                setPostShown(true);
                                                setPostData(jobData);
                                            }}
                                        />
                                    );
                                })}
                            </Box>
                            {
                            // <nav aria-label='job postings'>
                            //     <List>
                            //         <ListItem disablePadding>
                            //             <ListItemButton
                            //                 component='a'
                            //                 href={jobDoc}
                            //                 target='_blank'>
                            //                 <ListItemText primary='Independent contractor' />
                            //             </ListItemButton>
                            //         </ListItem>
                            //     </List>
                            // </nav>
                            }
                        </Box>
                        </React.Fragment> :  <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper
                                    sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    <Grid container>
                                    <Grid
                                        item
                                        xs={10}
                                        style={{
                                            display: 'flex',
                                            gap: '1rem',
                                            alignItems: 'center',
                                        }}>
                                        <Button
                                            onClick={()=> {
                                                setPostShown(false)
                                            }}
                                            variant='outlined'
                                            sx={{ m: 2 }}
                                            startIcon={<ArrowBackIcon />}>
                                            back
                                        </Button>
                    
                                        <GeneralModal
                                        IconButton={() => null}
                                        IconButtonEnd={() => <EditIcon />}
                                            formFields={formFields}
                                            title='Apply now'
                                            handleSend={async (data) => {
                                                let url = '/api/inquire/apply';
                                                data.jobTitle = postData.title;
                                                const response = await fetch(url, {
                                                    method: 'POST', // or 'PUT'
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        authorization: `token ${authManager.authToken}`,
                                                    },
                                                    body: JSON.stringify(data),
                                                });
                    
                                                if (response.ok) {
                                                    const result = await response.json();
                                                    // handleGetJobPosts();
                                                    console.log('Success:', result);
                                                    return response;
                                                }
                                                if (!response.ok) {
                                                    return response;
                                                }
                                            }}
                                        />
                        
                                    </Grid>
                                </Grid>
                                    <Title>{postData.title}</Title>
                                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                    location: {postData.location}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                rate: {postData.rate}
                </Typography>
                <Box sx={{ width: '100%', p: 2 }}>
                    {parse(postData.description, options)}
                </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>}
                        
                    </Box>
                </Box>
            </Box>
            <Divider />
            <Footer />
        </React.Fragment>
    );
}

export default Careers;
