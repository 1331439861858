import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import palmetto_infusion from '../assets/logos/palmetto-infusion.jpg'
import amerita_logo from '../assets/logos/amerita-logo.png'
import virtue_logo from '../assets/logos/virtue-script-logo.webp'
import OnTime_360 from '../assets/logos/OnTime-360-logo.png'
import piedmont from '../assets/logos/piedmont-healthcare-vector-logo.svg'
import SH_logo from '../assets/logos/SH_ogo.webp'
 
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
    {
        label: 'palmetto infusion logo',
        imgPath: palmetto_infusion,
    },
    {
        label: 'amerita logo',
        imgPath: amerita_logo,
    },
    {
        label: 'virtue script logo',
        imgPath: virtue_logo,
    },
    {
        label: 'OnTime 360 logo',
        imgPath: OnTime_360,
    },
    {
        label: 'piedmont logo',
        imgPath: piedmont,
    },
    {
        label: 'shepard health lab',
        imgPath: SH_logo
    }

   
];

function LogoBar() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ maxWidth: 500, flexGrow: 1, backgroundColor: 'white' }}>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents>
                {images.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                component='img'
                                sx={{
                                    // height: 255,
                                    maxHeight: 200,
                                    display: 'block',
                                    maxWidth: 400,
                                    overflow: 'hidden',
                                    width: '100%',

                                }}
                                src={step.imgPath}
                                alt={step.label}
                            />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position='static'
                activeStep={activeStep}
                nextButton={
                    <Button
                        size='small'
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button
                        size='small'
                        onClick={handleBack}
                        disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                    </Button>
                }
            />
        </Box>
    );
}

export default LogoBar;
