import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    useLocation,
    Navigate,
    useNavigate,
} from 'react-router-dom';
import Home from './pages/Home';
import LandingPage from './pages/LandingPage';
import AppAppBar from './components/AppAppBar';
import Location from './pages/Location';
import UnderWork from './pages/UnderWork';
import Footer from './components/Footer';
import {
    Box,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Paper,
} from '@mui/material';
import AboutUs from './pages/AboutUs';
import OpsAlerts from './pages/OpsAlerts';
import Careers from './pages/Careers';
import DashboardRoot from './pages/dashboard/DashboardRoot';
import { useUserContext } from './utilities/context/userContext';
import Login from './pages/dashboard/Login';

function NoMatch() {
    let location = useLocation();

    return (
        <div className='App-header'>
            <h3>
                No match for <code>{location.pathname}</code>
            </h3>
            <Link to='/'>Back to Home</Link>
        </div>
    );
}

function App() {
    const [mode, setMode] = useState('light');
    const authManager = useUserContext();
    // const location = useLocation();
    // const { hash, pathname, search } = location;

    function saveTheme(value) {
        window.localStorage.setItem('THEME', value);
    }
    function getTheme() {
        return window.localStorage.getItem('THEME');
    }

    useEffect(() => {
        const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
        const theme = getTheme();
        if (theme !== null || undefined) {
            setMode(theme);
        } else {
            if (darkThemeMq.matches) {
                // Theme set to dark.
                setMode('dark');
                saveTheme('dark');
            } else {
                // Theme set to light.
                setMode('light');
                saveTheme('light');
            }
        }
        return () => {};
    }, []);

    const Loader = ({ children }) => {
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            const timer = setTimeout(() => {
                setLoading(false);
            }, 110);
            return () => {
                clearTimeout(timer);
            };
        }, []);
        return (
            <React.Fragment>
                {loading ? (
                    <Container maxWidth='lg' height='100%' sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '80vh'
                                    }}>
                                    <CircularProgress
                                        size={80}
                                        sx={{
                                            alignSelf: 'center',
                                            marginTop: '20%'                                           
                                        }}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                ) : (
                    <React.Fragment>{children}</React.Fragment>
                )}
            </React.Fragment>
        );
    };

    const toggleColorMode = () => {
        setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
        saveTheme(mode === 'dark' ? 'light' : 'dark');
    };
    const defaultTheme = createTheme({ palette: { mode } });
    // let location = useLocation();
    // let gint = window.location.href;
    return (
        <ThemeProvider theme={defaultTheme}>
            <BrowserRouter>
                <CssBaseline />

                <Routes>
                    <Route
                        path='/'
                        element={
                            <LandingPage
                                mode={mode}
                                toggleColorMode={toggleColorMode}
                            />
                        }
                    />
                    <Route
                        path='/login'
                        element={
                            <Login
                                mode={mode}
                                toggleColorMode={toggleColorMode}
                            />
                        }
                    />
                    <Route
                        path='/about-us'
                        element={
                            <AboutUs
                                mode={mode}
                                toggleColorMode={toggleColorMode}
                            />
                        }
                    />
                    <Route
                        path='/Locations'
                        element={
                            <Location
                                mode={mode}
                                toggleColorMode={toggleColorMode}
                            />
                        }
                    />
                    <Route
                        path='/operational-alerts'
                        element={
                            <OpsAlerts
                                mode={mode}
                                toggleColorMode={toggleColorMode}
                            />
                        }
                    />
                    <Route
                        path='/track-your-delivery'
                        element={<UnderWork />}
                    />
                    <Route
                        path='/careers'
                        element={
                            <Careers
                                mode={mode}
                                toggleColorMode={toggleColorMode}
                            />
                        }
                    />
                    <Route
                        path='/dashboard/*'
                        element={
                            authManager.isAuth !== true ? (
                                <Loader>
                                    <Login
                                        mode={mode}
                                        toggleColorMode={toggleColorMode}
                                    />
                                </Loader>
                            ) : (
                                <Loader>
                                    <DashboardRoot
                                        mode={mode}
                                        toggleColorMode={toggleColorMode}
                                    />
                                </Loader>
                            )
                        }
                    />
                    <Route path='*' element={<NoMatch />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
