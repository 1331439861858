import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Footer from '../components/Footer';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import GroupIcon from '@mui/icons-material/Group';
import NCE_courier from '../assets/pics/random_courier5.png';
import NCE_courier4 from '../assets/pics/random_courier4.jpg';
import WhyChooseUs from '../components/WhyChooseUs';
import OurPartners from '../components/OurPartners';
import InspiringQuotes from '../components/InspiringQuotes';
import AppAppBar from '../components/AppAppBar';

const items1 = [
    {
        icon: <LocalShippingIcon />,
        title: 'Cost-Effective Delivery Solutions',
        description: `
        Our team of logistics experts will provide you with specific cost-saving options,
        such as optimized route planning and bulk delivery discounts,
        to help reduce your delivery costs. We have a proven formula that ensures on-time delivery without compromising quality.`,
    },
    {
        icon: <MedicalInformationIcon />,
        title: 'Keep Taking Care of Patients',
        description:
            'We offer a convenient service that allows you to focus on patient care. As your trusted logistics partner, let us handle timely deliveries so you can concentrate on running your pharmacy or practice.',
    },
    {
        icon: <GroupIcon />,
        title: 'Bloodborne and HIPAA Certified Drivers',
        description:
            'Supporting our commitment to market-leading customer service is our membership with the National CPR Foundation. Our drivers, who are insured and bonded, are certified in bloodborne pathogens and HIPAA, ensuring the highest level of professionalism in their work.',
    },
];
const items2 = [
    {
        icon: <LocalShippingIcon />,
        title: '',
        description: `NCE offers expedited delivery and courier services tailored to your specific requirements`,
    },
    {
        icon: <LocalShippingIcon />,
        title: '',
        description: `We operate 24/7 and have a diverse fleet of vehicles and medically trained drivers.`,
    },
    {
        icon: <LocalShippingIcon />,
        title: '',
        description: `Whether you need Same-Day STAT Delivery, Next-Day Timed Delivery, or Next-Day Delivery by 5 p.m., we've got you covered..`,
    },
];

export default function LandingPage({ mode, toggleColorMode }) {
    return (
        <React.Fragment>
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
            <Hero />
            <Box sx={{ bgcolor: 'background.default' }}>
                <Box sx={{ p: 2 }}>
                    <Features
                        direction={'left'}
                        id='What we do'
                        titleP1={'What'}
                        titleP2={'we do'}
                        img={NCE_courier}
                        items={items1}
                    />
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                    <Features
                        direction={'right'}
                        id='Our services'
                        titleP1={'Our services'}
                        titleP2={''}
                        img={NCE_courier4}
                        items={items2}
                    />
                </Box>
                <Divider />
                <WhyChooseUs />
                <OurPartners />
                <Divider />
                <InspiringQuotes />
                <Divider />
            </Box>
            <Divider />
            <Footer />
        </React.Fragment>
    );
}
