import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@emotion/react';

const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';

const IconBox = ({ icon }) => {
    return (
        <Box
            sx={{
                color: (theme) => {
                    if (theme.palette.mode === 'light') {
                        return primary_color;
                    }
                    return primary_color_dark;
                },
            }}>
            {icon}
        </Box>
    );
};

const ImgContainer = ({ src }) => {
    return (
        <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xs: 'none', md: 'flex' }, width: '100%' }}>
            <Box
                variant='outlined'
                sx={{
                    m: 'auto',
                    alignSelf: 'center',
                    height: { xs: 200, sm: 500 },
                    width: '100%',
                    border: 1,
                    borderRadius: 5,
                    borderColor: (theme) => {
                        if (theme.palette.mode === 'light') {
                            return 'grey.200';
                        }
                        return 'grey.800';
                    },
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(${src})`,
                }}
            />
        </Grid>
    );
};

export default function Features({
    direction = 'left',
    id = '',
    titleP1,
    titleP2 = '',
    img,
    items,
}) {
    const { palette } = useTheme();

    return (
        <Box id={id} sx={{ py: { xs: 8, sm: 10 } }}>
            <Grid container spacing={6}>
                {direction === 'right' ? <ImgContainer src={img} /> : null}
                <Grid item xs={12} md={6}>
                    <div>
                        <Typography
                            component='h2'
                            variant='h4'
                            color={
                                palette.mode === 'light'
                                    ? primary_color
                                    : primary_color_dark
                            }
                            sx={{
                                fontSize: 'clamp(3rem, 10vw, 4rem)',
                            }}>
                            {titleP1}&nbsp;
                            {titleP2 !== '' ? (
                                <Typography
                                    component='span'
                                    variant='h4'
                                    color={
                                        palette.mode === 'light'
                                            ? secondary_color
                                            : secondary_color_dark
                                    }
                                    sx={{
                                        fontSize: 'clamp(3rem, 10vw, 4rem)',
                                    }}>
                                    {titleP2}
                                </Typography>
                            ) : null}
                        </Typography>
                    </div>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                            width: '100%',
                        }}>
                        <Box
                            variant='outlined'
                            sx={{
                                m: 'auto',
                                alignSelf: 'center',
                                height: { xs: 200, sm: 500 },
                                width: '100%',
                                border: 1,
                                borderRadius: 5,
                                borderColor: (theme) => {
                                    if (theme.palette.mode === 'light') {
                                        return 'grey.200';
                                    }
                                    return 'grey.800';
                                },
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundImage: `url(${img})`,
                            }}
                        />
                    </Grid>
                    <Stack
                        direction='column'
                        justifyContent='center'
                        alignItems='flex-start'
                        spacing={2}
                        useFlexGap
                        sx={{
                            width: '100%',
                            display: { xs: 'flex', sm: 'flex' },
                        }}>
                        {items.map(({ icon, title, description }, index) => (
                            <Card
                                key={index}
                                variant='outlined'
                                sx={{
                                    p: 3,
                                    height: 'fit-content',
                                    width: '100%',
                                    background: 'none',
                                    borderColor: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return 'grey.200';
                                        }
                                        return 'grey.800';
                                    },
                                }}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        textAlign: 'left',
                                        flexDirection: {
                                            xs: 'column',
                                            md: 'row',
                                        },
                                        alignItems: { md: 'center' },
                                        gap: 2.5,
                                    }}>
                                    {direction === 'left' ? (
                                        <IconBox icon={icon} />
                                    ) : null}
                                    <Box sx={{ textTransform: 'none' }}>
                                        <Typography
                                            color={
                                                palette.mode === 'light'
                                                    ? primary_color
                                                    : primary_color_dark
                                            }
                                            variant='body2'
                                            fontWeight='bold'>
                                            {title}
                                        </Typography>
                                        <Typography
                                            color='text.secondary'
                                            variant='body2'
                                            sx={{ my: 0.5 }}>
                                            {description}
                                        </Typography>
                                    </Box>
                                    {direction === 'right' ? (
                                        <IconBox icon={icon} />
                                    ) : null}
                                </Box>
                            </Card>
                        ))}
                    </Stack>
                </Grid>
                {direction === 'left' ? <ImgContainer src={img} /> : null}
            </Grid>
        </Box>
    );
}
