import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';

export default function WhyChooseUs() {
    return (
        <Box
            id='Why Choose Us'
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                color: 'white',
                bgcolor: '#06090a',
            }}>
            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}>
                <Box
                    sx={{
                        width: { sm: '100%', md: '60%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}>
                    <Typography component='h2' variant='h4'>
                        Why Choose Us
                    </Typography>
                    <Typography variant='body1' sx={{ color: 'grey.400' }}>
                        Our dedicated medical professional team, with years of
                        experience, delivers your package with compassion, care,
                        knowledge, speed, accuracy, and certainty.
                    </Typography>
                    <Typography variant='body1' sx={{ color: 'grey.400' }}>
                        Our extensive expertise, understanding, and 'can-do'
                        approach set us apart, making us the logical go-to
                        company for those needing a reliable partner in
                        supply-chain service.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}
