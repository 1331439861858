import React, { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { Box, Button, Divider, Grid, LinearProgress } from '@mui/material';
import { useUserContext } from '../../utilities/context/userContext';
import SnackbarBase from '../SnackbarBase';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import LocationModal from './LocationModal';

export default function LocationsAdmin() {
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [didError, setDidError] = useState(false);

    const authManager = useUserContext();
    const handleGetLocations = async () => {
        setLoading(true);
        try {
            const response = await fetch('/api/locations/', {
                method: 'Get', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                const result = await response.json();
                setLocations(result);
                debugger;
            } else {
                setDidError(true);
            }
        } catch (error) {
            setDidError(true);
            setLoading(false);
        }
        setLoading(false);
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
            handleGetLocations();
        }, 600);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const handleDelete = async (user) => {
        setLoading(true);
        try {
            const response = await fetch(`/api/locations/${user._id}`, {
                method: 'delete', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `token ${authManager.authToken}`,
                },
            });
            if (response.ok) {
                const result = await response.json();
                if (result.acknowledged === true) {
                    debugger;
                    handleGetLocations();
                }
            } else {
                setDidError(true);
            }
        } catch (error) {
            setDidError(true);
            setLoading(false);
        }
        setLoading(false);
    };

    return (
        <React.Fragment>
            <SnackbarBase
                isOpen={didError}
                setIsOpen={setDidError}
                type={'error'}
                message={
                    'there was a problem retrieving user information. Please try again later'
                }
            />
            <Title>Locations</Title>
            {loading ? <LinearProgress /> : null}
            <Divider />
            <Grid container>
                <Grid
                    item
                    xs={10}
                    style={{
                        display: 'flex',
                        gap: '1rem',
                        alignItems: 'center',
                    }}>
                    <Button
                        onClick={handleGetLocations}
                        variant='outlined'
                        sx={{  m: 2 }}
                        startIcon={<RefreshIcon />}>refresh</Button>
                        
                    <LocationModal refreshHandler={handleGetLocations}/>
                </Grid>
            </Grid>

            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell align='right'></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {locations.map((location) => (
                        <TableRow key={location._id}>
                            <TableCell>{location.title}</TableCell>
                            <TableCell>{location.phone}</TableCell>
                            <TableCell>{location.address}</TableCell>
                            <TableCell align='right'>
                                <Button
                                    onClick={() => {
                                        handleDelete(location);
                                    }}
                                    variant='outlined'
                                    color='error'
                                    startIcon={<DeleteIcon />}>
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
