import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Users from '../../components/dashboard/Users';

const AccountManagement = () => {
    return (
        <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <Users />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};
export default AccountManagement;
