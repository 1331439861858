import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import NCE_logo from '../assets/NCE_Logo.png';
import NCE_logo_dark from '../assets/NCE_Logo_dark.png';
import { useLocation } from 'react-router-dom';

const logoStyle = {
    width: '360px',
    height: 'auto',
};

const primary_color = '#283B6C';
const secondary_color = '#4589BC';

const primary_color_dark = '#4161B0';
const secondary_color_dark = '#56A9E8';

export default function Footer() {
    const themeColor = useTheme();
    const location = useLocation();

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        const offset = 128;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({ behavior: 'smooth' });
            window.scrollTo({
                top: targetScroll,
                behavior: 'smooth',
            });
        }
    };
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 4, sm: 8 },
                pt: { xs: 3, sm: 5 },
                pb: { xs: 1, sm: 3 },
                textAlign: { sm: 'center', md: 'left' },
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                    width: '100%',
                    justifyContent: 'space-between',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        minWidth: { xs: '100%', sm: '40%' },
                    }}>
                    <Box sx={{ width: { xs: '100%' } }}>
                        <Box sx={{ ml: '-15px' }}>
                            <img
                                src={
                                    themeColor.palette.mode === 'light'
                                        ? NCE_logo
                                        : NCE_logo_dark
                                }
                                style={logoStyle}
                                alt='logo of sitemark'
                            />
                            <Box display='inline'>
                                <Typography color='text.secondary'>
                                    Phone: (833) 278-2663 or 2NCE.
                                </Typography>
                                <br />
                                <Typography color='text.secondary'>
                                    Address: 125 Town Park Dr., Suite 300
                                    Kennesaw, GA 30144
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: { xs: '100%' },
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}>
                    <Typography
                        component='h4'
                        variant='h4'
                        sx={{
                            color: (theme) => {
                                if (theme.palette.mode === 'light') {
                                    return primary_color;
                                }
                                return primary_color_dark;
                            },
                        }}>
                        Get a Quote Today
                    </Typography>
                    <Typography display='inline' color='text.secondary'>
                        (833) 278-2623 opt:3
                    </Typography>

                    <Link color='text.secondary' href='#'>
                        Sales@NationalCourierExpress.com
                    </Link>
                </Box>
                {location.pathname === '/' ? (
                    <React.Fragment>
                        <Box
                            sx={{
                                width: { xs: '100%' },
                                display: { sm: 'flex' },
                                flexDirection: 'column',
                                gap: 2,
                            }}>
                            <Typography
                                variant='body2'
                                fontWeight={600}
                                sx={{
                                    display: { xs: 'flex' },
                                    alignSelf: 'center',
                                }}>
                                Company
                            </Typography>

                            <Link
                                sx={{
                                    display: { xs: 'flex' },
                                    alignSelf: 'center',
                                }}
                                color='text.secondary'
                                onClick={() => scrollToSection('What we do')}>
                                What we do
                            </Link>
                            <Link
                                sx={{
                                    display: { xs: 'flex' },
                                    alignSelf: 'center',
                                }}
                                color='text.secondary'
                                onClick={() => scrollToSection('Our services')}>
                                Our services
                            </Link>

                            <Link
                                sx={{
                                    display: { xs: 'flex' },
                                    alignSelf: 'center',
                                }}
                                color='text.secondary'
                                onClick={() =>
                                    scrollToSection('Why Choose Us')
                                }>
                                Why Choose Us
                            </Link>
                            {
                                //   <Link color="text.secondary" href="#">
                                //   Careers
                                // </Link>
                                // <Link color="text.secondary" href="#">
                                //   Press
                                // </Link>
                            }
                        </Box>
                        <Box
                            sx={{
                                display: { xs: 'none', sm: 'flex' },
                                flexDirection: 'column',
                                gap: 1,
                            }}>
                            <Stack
                                direction='row'
                                justifyContent='left'
                                spacing={1}
                                useFlexGap
                                sx={{
                                    color: 'text.secondary',
                                }}>
                                <IconButton
                                    color='inherit'
                                    href='https://www.linkedin.com/in/kevin-figa-a21a502b0/'
                                    target='_blank'
                                    aria-label='LinkedIn'
                                    sx={{ alignSelf: 'center' }}>
                                    <LinkedInIcon />
                                </IconButton>
                                <IconButton
                                    color='inherit'
                                    href='https://x.com/NceCourier'
                                    target='_blank'
                                    aria-label='X'
                                    sx={{ alignSelf: 'center' }}>
                                    <TwitterIcon />
                                </IconButton>
                                <IconButton
                                    color='inherit'
                                    href='https://www.instagram.com/nationalcourierexpress/'
                                    target='_blank'
                                    aria-label='LinkedIn'
                                    sx={{ alignSelf: 'center' }}>
                                    <InstagramIcon />
                                </IconButton>
                            </Stack>
                        </Box>
                    </React.Fragment>
                ) : null}
                {
                    //<Box
                    //   sx={{
                    //     display: { xs: 'none', sm: 'flex' },
                    //     flexDirection: 'column',
                    //     gap: 1,
                    //   }}
                    // >
                    //   <Typography variant="body2" fontWeight={600}>
                    //     Legal
                    //   </Typography>
                    //   <Link color="text.secondary" href="#">
                    //     Terms
                    //   </Link>
                    //   <Link color="text.secondary" href="#">
                    //     Privacy
                    //   </Link>
                    //   <Link color="text.secondary" href="#">
                    //     Contact
                    //   </Link>
                    // </Box>
                }
            </Box>
        </Container>
    );
}
